import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PhotoGrid from "../components/PhotoGrid"
import MapGL, { FullscreenControl, NavigationControl, Source, Layer, FeatureState, Marker, Popup } from '@urbica/react-map-gl';
import Cluster from '@urbica/react-map-gl-cluster';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useMapboxConfig } from "../hooks/mapbox-token";
import Img from "gatsby-image"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const IndexPage = ({data, pageContext}) => {

  const locations = data.allLocations.nodes;

  const city = locations[0].city;

  const geo = data.geo.nodes;

  const mapboxToken = useMapboxConfig().mapboxToken;
  const [viewport, setViewport] = useState({
    latitude: geo[geo.length - 1].lat,
    longitude: geo[geo.length - 1].lng,
    zoom: 11
  })

  const ClusterMarker = ({ longitude, latitude, pointCount }) => (
    <Marker longitude={longitude} latitude={latitude}>
      <div style={{ ...style, background: '#B4192E' }}>{pointCount}</div>
    </Marker>
  );

  const style = {
    width: '20px',
    height: '20px',
    color: '#fff',
    background: 'blue',
    borderRadius: '20px',
    textAlign: 'center'
  };

  const currentPage = pageContext.currentPage;
  const numPages = pageContext.numPages;

  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? pageContext.cityFullPath : `${pageContext.cityFullPath}/${(currentPage - 1).toString()}`;
  const nextPage = `${pageContext.cityFullPath}/${(currentPage + 1).toString()}`;

  return (
    <Layout>
      <SEO title={`Photos from ${city}`} image={data.placeholderImage.childImageSharp.fluid.src}/>

      <div className="breadcrumbs">
        <Link onClick={e => { trackCustomEvent({ category: "navigation", action: "country", label: locations[0].country }) }} to='/cities'>{locations[0].country}</Link> /
        <Link onClick={e => { trackCustomEvent({ category: "navigation", action: "state", label: locations[0].state }) }} to='/cities'> {locations[0].state}</Link> /
        <Link onClick={e => { trackCustomEvent({ category: "navigation", action: "city", label: locations[0].city }) }} to={locations[0].fields.cityFullPath}> {locations[0].city}</Link>
      </div>
      <h2><Link to={pageContext.cityFullPath}>Signs from {city}</Link></h2>
      {currentPage === 1 && (
        <MapGL
          style={{ height: '400px' }}
          mapStyle="mapbox://styles/mapbox/light-v9"
          accessToken={mapboxToken}
          latitude={viewport.latitude}
          longitude={viewport.longitude}
          zoom={viewport.zoom}
          onViewportChange={(viewport) => { setViewport(viewport)}}
        >
          <FullscreenControl position='top-right' />
          <NavigationControl showCompass showZoom position='top-right' />

          <Cluster radius={50} extent={500} nodeSize={1004} component={ClusterMarker}>
            {geo.map((item) => (
              <Popup className='popup' longitude={item.lng} latitude={item.lat} closeOnClick={false}>
                <a onClick={e => {trackCustomEvent({category: "navigation", action: "open-from-map"})}} target="_blank" rel="noopener noreferrer" href={`/${item.fields.path}`}>
                  <Img fixed={item.images[0].src.childImageSharp.fixed} />
                </a>
              </Popup>
            ))}
          </Cluster>
        </MapGL>
      )}

      <section>
        {currentPage === 1 && (
          <h3>All photos</h3>
        )}
        {currentPage > 1 && (
          <h3>Page {currentPage}</h3>
        )}

        <PhotoGrid showArrow={false} title={city} photos={locations}></PhotoGrid>
        {!isFirst && (
          <Link className="more-link" to={prevPage} rel="prev">
            ← Later
          </Link>
        )}
        {!isLast && (
          <Link className="more-link" to={nextPage} rel="next">
            Earlier →
          </Link>
        )}
      </section>
    </Layout>
  )
}
  
export default IndexPage

export const pageQuery = graphql`
  query PhotosByCityPath($cityFullPath: String, $skip: Int!, $limit: Int!) {
    placeholderImage: file(relativePath: { eq: "banner-narrow.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allLocations: allLocationsJson(sort: {fields: images___date, order: DESC}, limit: $limit, skip: $skip, filter: {fields: {cityFullPath: {eq: $cityFullPath}}}) {
      nodes {
        lng
        lat
        fields {
          path
          cityFullPath
        }
        sublocation
        city
        state
        country
        images {
          title
          caption
          credit
          src {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      totalCount
    }
    geo: allLocationsJson(sort: {fields: images___date, order: DESC}, filter: {fields: {cityFullPath: {eq: $cityFullPath}}}) {
      nodes {
        lng
        lat
        fields {
          path
        }
        images {
          src {
            childImageSharp {
              fixed(width: 55, height: 73) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
      totalCount
    }
  }
`